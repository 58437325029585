import {
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField, SelectSx } from "../theme/vs-theme";
import axios from "axios";
import dotenv from "react-dotenv";
import { useAuth } from "../hooks/useAuth";

const BusinessModelForm = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const handleChange = (event) => {
    setBusinessModel(event.target.value);
    props.setForm({ ...props.form, type_plan: event.target.value });
  };
  const [errors, setErrors] = useState({});
  const [businessModel, setBusinessModel] = useState({});
  const [pathologies, setPathologies] = useState([]);
  const handlePriceChange = (event) => {
    if (isNaN(event.target.value)) return;
    props.setForm({
      ...props.form,
      pathology_prices: {
        ...props.form.pathology_prices,
        [event.target.id]: event.target.value,
      },
    });
  };

  const styles = {
    buttons: {
      marginTop: "20px",
      borderRadius: "15px",
      color: "white",
      margin: "20px",
    },
    title: {
      color: "#737589",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.32px",
      paddingBottom: "16px",
    },
    blueTitle: {
      color: "#3657D9",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      letterSpacing: "0.32px",
      paddingBottom: "16px",
    },
    paper: {
      backgroundColor: "white",
      padding: 4,
      width: "100%",
      marginBottom: 2,
    },
    smallPaper: {
      backgroundColor: "#E7E9FF",
      padding: "20px",
      borderRadius: "15px",
    },
    adornment: {
      paddingLeft: "16px",
      fontWeight: 600,
      color: "#3657D9",
      userSelect: "none",
    },
  };

  const adornment = (
    <InputAdornment position="start">
      <Typography sx={styles.adornment}>$</Typography>
    </InputAdornment>
  );

  const fetchData = async () => {
    const url = new URL("/institutions/v1/pathologies", dotenv.API_URL);
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setPathologies(response.data);
      })
      .catch((error) => {
        props.showMessage(t("error_loading_patologies"), () => {
          //
        });
      });
  };

  useEffect(() => {
    if (props.form) {
      setBusinessModel(props.form.type_plan);
    }
  }, [props.form]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid>
      <Paper sx={styles.paper} elevation={0}>
        <Typography sx={styles.title}>{t("prices")}</Typography>
        {props.selectModel ? (
          <Grid container sx={{ marginBottom: "16px" }}>
            <Grid item xs={4}>
              <FormControl variant="outlined" required>
                <FormHelperText sx={{ color: "#869AE8", margin: "0px 3px" }}>
                  {t("business_model")}
                </FormHelperText>
                <Select
                  sx={SelectSx}
                  id="id-type-input"
                  name="business_model"
                  value={businessModel}
                  onChange={handleChange}
                >
                  <MenuItem value={1}>{t("patient")}</MenuItem>
                  <MenuItem value={2}>{t("provider")}</MenuItem>
                </Select>
                <FormHelperText
                  id="id-type-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors.id_type_id && errors.id_type_id[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        <Grid container spacing={2}>
          {pathologies.map((pathology) => {
            return (
            <Grid item xs={4}>
              <Paper elevation={0} sx={styles.smallPaper}>
                <FormControl variant="standard" required>
                  <InputLabel shrink htmlFor="name-input">
                    {pathology.name}
                  </InputLabel>
                  <TextField
                    startAdornment={adornment}
                    id={`${pathology.id}`}
                    name={pathology.name}
                    onChange={handlePriceChange}
                    value={props.form.pathology_prices[pathology.id]}
                  />
                  <FormHelperText
                    id="name-input-error-text"
                    sx={{ color: "red" }}
                  ></FormHelperText>
                </FormControl>
              </Paper>
            </Grid>
            )}
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default BusinessModelForm;
